import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { css } from "@emotion/react";
import Swal from "sweetalert2";
import CJLogo from "./CJ.png";
import { postData } from "../../Services/NodeServices";
import { useNavigate } from "react-router";
import { useContext } from 'react';
import { SessionContext } from "../../Services/SessionContext";
//socket
import { io } from 'socket.io-client'
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const PaymentGateway = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const { notification,setNotification } = useContext(SessionContext);
  const { name, mobileNumber, total,address,pincode,city,district,state,products } = location.state;
  const mail=window.localStorage.getItem('UserMail')
  const number=window.localStorage.getItem('UserNumber')
  // alert(mail)

  const [socket,setSocket]=React.useState()

  React.useEffect(() => {
          const socket = io("https://api3.buzz2web.in/");
          setSocket(socket);
        
          socket.on("connect", () => {
            console.log(socket.id);
            socket.emit("chandan",'order');
           
          });
          
  
        }, []);


  const handleOrderDetails =async(response)=>{
       
    var body={mobile:number==null?mail:number,name:name,address:address+' '+pincode+' '+city+' '+district+' '+state,cart:products,totalAmount:total,paymentId:response.razorpay_payment_id}
    var result = await postData('order/bookedOrders',body)
    console.log(result.status)
    if (result.status==true) {
      const socket = io("https://api3.buzz2web.in/");
     
    
      socket.on("connect", () => {
        
        socket.emit("newEntry",{Enquiry:'New Enquiry',room:'order'})

      });
      
        Swal.fire({
            icon: "success",
            title: "Done",
            title: "Ordered Succesfully",
        });
        var body1={mobile:number==null?mail:number}
        var result = await postData(`cart/cartsdelete`,body1)
        navigate('/')
        setNotification(true)
        
    } else {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
        });
        navigate('/')
    }
  }

  const options = {
    key: "rzp_test_GQ6XaPC6gMPNwH",
    amount: total * 100, //  = INR 1
    name: "Chandan Jewellers",
    image: CJLogo,
    handler: async function (response) {
      
      handleOrderDetails(response)
    
    },
    prefill: {
      name,
      contact: mobileNumber,
    },
    notes: {
      address: "some address",
    },
    theme: {
      color: "rgb(255, 148, 202)",
      hide_topbar: false,
    },
  };

  useEffect(() => {
    const rzp = new window.Razorpay(options);

    rzp.on("payment.failed", function (response) {
      console.log(response.error);
    });

    rzp.open();

    return () => {
      rzp.close();
    };
  }, []);

  return null;
};

export default PaymentGateway
// import React, { Component, useEffect, useState } from "react";
// import { Button } from "@material-ui/core";
// import { withStyles } from "@material-ui/core/styles";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate,useLocation } from "react-router";
// import { css } from "@emotion/react";
// import Swal from "sweetalert2";
// import { postData,getData } from "../../Services/NodeServices";
// import CJLogo from "./CJ.png";

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;

// const styles = (theme) => ({
//     root: {
//         width: "100%",
//         // marginTop: theme.spacing.unit * 3,
//         overflowX: "auto",
//     },
//     table: {
//         minWidth: 700,
//     },
//     icon: {
//         margin: theme.spacing.unit,
//         fontSize: 32,
//     },
//     margin: {
//         marginRight: "80%",
//         paddingLeft: "",
//     },
//     button: {
//         margin: theme.spacing.unit,
//     },

//     rightIcon: {
//         marginLeft: theme.spacing.unit,
//     },
// });

// const PaymentGateway = (props) => {
//     const location = useLocation()
//     const fullname=location.state.name
//     const mobilenumber=location.state.mobileNumber
//     const total=location.state.total

//     const options = {
//         key: "rzp_test_GQ6XaPC6gMPNwH",
//         amount: total * 100, //  = INR 1
//         name: "Chandan Jewellers",
//         // description: 'some description',
//         image:CJLogo,
//         handler: async function (response) {

//             // let id = response.razorpay_payment_id
//            ]
//             // handleSubmit(id)
//             window.location.href = `/`
//         },
//         prefill: {
//             name: fullname,
//             contact: mobilenumber,
      
//         },
//         notes: {
//             address: "some address",
//         },
//         theme: {
//             color: "rgb(255, 148, 202)",
//             hide_topbar: false,
//         },
//     };


//     const gotoRazorpay = () => {
//         return (

//             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

//                 <div style={{ fontSize: 30, fontWeight: 'bold', color: 'GrayText', padding: 20 }}>Redirecting to Razorpay pls wait........</div>

//                 <div className="sweet-loading">
//                     {openPayModal()}
//                 </div>
//             </div>
//         )
//     }

//     const openPayModal = () => {
//         const rzp = new window.Razorpay(options);
//         rzp.open();
//     }

//     useEffect(() => {
//         const script = document.createElement("script");
//         script.src = "https://checkout.razorpay.com/v1/checkout.js";
//         script.async = true;
//         document.body.appendChild(script);

//     }, []);

//     const { classes } = props;

//     return (
//         <div>
//             {gotoRazorpay()}
//         </div>
//     );
// };

// export default withStyles(styles)(PaymentGateway);