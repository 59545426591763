import React from "react";
import { useEffect, useState } from "react";
import { postData } from "../../Services/NodeServices";
import { useParams } from "react-router";
import { ProductsDetailComponent } from "./ProductsDetailComponent";
import MainBar from "./MainBar";
import SearchBar from "./SearchBar";
import DownBox from "./DownBox";
import { Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import FilterCompomnent from "./FilterComponent";
import { serverURL } from "../../Services/NodeServices";
import { useLocation } from "react-router";
import LineImage from "../../Assets/line.jpg";

export default function ProductList3() {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(900));

    const [getProduct, setProduct] = useState([])
    var location = useLocation()

    const keywords = location.state.keywords
    const FetchProductByKeywords = async (keywords) => {

        var body = { keywords: keywords }
        var result = await postData('userinterface/search', body)
        setProduct(result.data)
        console.log(result.data)
    }

    useEffect(() => {
        FetchProductByKeywords(keywords)
    }, [keywords]);

    return (<div >
        <SearchBar />
        <MainBar />
        <Container maxWidth={"xl"}>
           
            <div style={{ display: 'flex', marginTop: 60, flexDirection: "column", flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '100%', height: 'auto' }}>
                    <img src={LineImage} style={{ width: '100%', height: mobile ? 15 : 40 }} />
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: 40, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                <ProductsDetailComponent data={getProduct} />
            </div>
            <div style={{ display: 'flex', marginTop: 60, flexDirection: "column", flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '100%', height: 'auto' }}>
                    <img src={LineImage} style={{ width: '100%', height: mobile ? 15 : 40 }} />
                </div>
            </div>
        </Container>
        <div
            style={{
                marginTop: 50,
                background: '#ffd1d9',
            }}
        >
            <Container maxWidth={"xl"}>
                <DownBox />
            </Container>
        </div>
    </div>)

}