import React, { useEffect,useState } from "react";
import MaterialTable from "@material-table/core";
import { getData, postData, serverURL } from "../Services/NodeServices";
// import React from "react";
import { Button,Grid,Avatar,TextField } from "@material-ui/core";
import { useStyles } from "./DisplayAllOrderCss";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import addNotification from 'react-push-notification';
import { useContext } from 'react';
import { io } from 'socket.io-client'
import logo from '../Assets/logo192.png'
import { SessionContext } from "../Services/SessionContext";
export default function DisplayAllOrder(props)
{ const classes=useStyles()
  const navigate=useNavigate()
  const[categoryID,setCategoryID]=useState(' ') 
  const[orderID,setOrderID]=useState(' ')
  const[customerName,setCustomerName]=useState('') 
  const[customerNumber,setCustomerNumber]=useState('') 
  const[deliveryAddress,setDeliveryAddress]=useState('') 
  const[totalAmount,setTotalAmount]=useState('') 
  const[paymentId,setPaymentId]=useState('') 
  const[products,setProducts]=useState([]) 
  const[cart,setCart]=useState([]) 
  const [getcategory,setcategory]=useState(' '); 
  const [Icon,setIcon]=useState({url:'/girl.png',bytes:''})
  const[oldicon,setOldicon]=useState(' ')
  const[uploadbtn,setuploadbtn]=useState(false)
  const { notification,setNotification } = useContext(SessionContext);
  const [socket,setSocket]=React.useState()
  const handlNotification=()=>{
    Notification.requestPermission().then(perm=>{
      if(perm=== "granted")
      {
        new Notification("New Order On Website",{
          body:'New Order Recieved',
          icon:logo
        })
      }
    })
  }

  React.useEffect(() => {
    const socket = io("https://api3.buzz2web.in/");
    setSocket(socket);
  
    socket.on("connect", () => {
      console.log(socket.id);
      socket.emit("chandan",'order');
      socket.on("new", (Enquiry) => {
        
        handlNotification()
        
    });
    });
  

  }, []);


    const  handleIcon=(event)=>{
     setIcon({url:URL.createObjectURL(event.target.files[0]) ,bytes:event.target.files[0]})
      setBtnStatus(true)
      setuploadbtn(true)
    
    }

    const buttonClick = () => {
      addNotification({
          title: 'Succes',
          subtitle: 'New Order',
          message: 'This is a very long message',
          theme: 'darkblue',
          native: true // when using native, your OS will handle theming.
      });
  };
   
  const [open,setOpen]=useState(false)
  const [btnStatus,setBtnStatus]=useState(false)

  const [category,setCategory]=useState([ ])
  const FetchAllCategory=async()=>{
 var data= await getData('order/display_all_order')

    setCategory(data.data)
  }

  useEffect(function(){
    
   
    FetchAllCategory()
  },[])

  useEffect(function(){
   
  },[socket])

  
     
  const handleOpen=(rowData)=>{ 
    setOrderID(rowData._id)
     setCustomerName(rowData.name)
     setCustomerNumber(rowData.mobile)
     setDeliveryAddress(rowData.address)
     setTotalAmount(rowData.totalAmount)
     setPaymentId(rowData.paymentId)
     setCart(rowData.cart)
     
    
    setOpen(true)
  }
  const handleClose=()=>{
    setOpen(false)
  }
  
  const handleCancel=()=>{
    setBtnStatus(false)
    setIcon({url:oldicon.url,bytes:''})
    setuploadbtn(false)
    setOldicon(' ')
  }

  const handleSavePicture=async()=>{
    var formdata= new FormData()
    formdata.append('categoryid',categoryID)
    formdata.append('icon',Icon.bytes)

   var response= await postData('category/update_icon',formdata,true)
   
    setBtnStatus(false)
    setuploadbtn(false)
    FetchAllCategory();

   
  }


  const SaveAndCancel=()=>{
    return(<div>
         {btnStatus?<div style={{display:'flex', width:180 ,justifyContent:'space-between'}}><Button onClick={handleSavePicture} color="primary" variant="contained">Save</Button>
          <Button color="secondary" variant="contained" onClick={handleCancel}>cancel</Button></div>:<></>}
           </div>)
  }
 

 const  showCategory=()=>{
        return(<div>
           <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        
        <DialogContent>

       
            
               <Grid container spacing={2}>
                <Grid item xs={12} style={{ fontSize:20,
        fontWeight:'bolder',}}>
               Order Details
                </Grid>
                <Grid item xs={12} >
                   Order Id : {orderID}
                </Grid>
                
                <Grid item xs={12} >
                   Customer Name : {customerName}
                </Grid>
                
                <Grid item xs={12} >
                   Address : {deliveryAddress}
                </Grid>
                <Grid item xs={12} >
                  <b>Product Details</b>
                   {
                   cart.map((item)=>{
                    
                    return(
                      <div>
                      Product Id: {item.productId}
                      {
                        
                      }
                      <div>
                     Count of This Product: {item.count}
                      
                      </div>

                      </div>
                     
                      ) 
                    
                    

                    
                   })}
                </Grid>
                
                
                <Grid item xs={12} >
                    Total Amount : ₹{totalAmount}
                </Grid>
                
                <Grid item xs={12} >
                   Payment Id : {paymentId}
                </Grid>
                
                
                
                 
               </Grid>
          

             
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{cursor:'pointer'}}>Close</Button>
    
        </DialogActions>
      </Dialog>
  </div>

        )

      }


        function DisplayCategory() {
            return (
             
              <MaterialTable
                title="List of "
                columns={[
                  { title: 'Order-ID', field: '_id' },
                  { title: 'Customer-Name', field: 'name' },
                  { title: 'Order Details',  render:(rowData)=><div><Button variant='contained' onClick={()=>handleOpen(rowData)}>Show</Button></div> },
                 
                 
                ]}
                data={category}        
              
              />
          
            )
          }
          
return(
  <div style={{ height:'100vh',
  background:'#f5f6fa',
  justifyContent:'center',
  display:'flex'}}>
    <div style={{borderRadius:20,
      width:'100%',
      height:'40%',
      background:'white ',
      marginTop:'3%'}}>
        {DisplayCategory()}
    </div>
         {showCategory()}
    </div>
)
   
}