import * as React from "react";

import List from "@mui/material/List";
import Divider, { dividerClasses } from "@mui/material/Divider";
import { Link } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import CategoryIcon from '@mui/icons-material/Category';
import AssignmentIcon from "@mui/icons-material/Assignment";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { Button, Grid } from "@material-ui/core";
import ViewListIcon from '@mui/icons-material/ViewList';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
export default function SideList(props) {

  const handleLogout = () => {
    window.localStorage.removeItem("Admin");
   
    window.location.reload();
};
  
  return (
    <Grid >
      <Grid style={{display:'flex',flexDirection:'column'}}>
        <Divider />
        <List component="nav">
         
          <Link style={{textDecoration:'none',color:'black',cursor:'pointer'}} to ='/chandanadmin' >
            <Button fullWidth style={{display:'flex',justifyContent:'flex-start',marginTop:10}}><DashboardIcon/>&nbsp;&nbsp;Dashboard</Button>
            </Link>
            <Link style={{textDecoration:'none',color:'black'}} to ='/chandanadmin/displayallcategory' >
             <Button fullWidth style={{display:'flex',justifyContent:'flex-start'}}><CategoryIcon/>&nbsp;&nbsp;Category</Button>
              </Link>

              <Link style={{textDecoration:'none',color:"black"}} to ='/chandanadmin/displaysubcategory' >
              <Button fullWidth style={{display:'flex',justifyContent:'flex-start'}}><CategoryIcon/>&nbsp;&nbsp;Sub Category</Button>
             </Link>

             <Link style={{textDecoration:'none',color:'black'}} to ='/chandanadmin/displayallproduct' >
             <Button fullWidth style={{display:'flex',justifyContent:'flex-start'}}><ProductionQuantityLimitsIcon/>&nbsp;&nbsp;Product</Button>
            </Link>

           
            <Link style={{textDecoration:'none',color:'black'}} to ='/chandanadmin/order' >
            <Button fullWidth style={{display:'flex',justifyContent:'flex-start'}}><ViewListIcon/>&nbsp;&nbsp;Orders</Button>
            </Link>
            <Link style={{textDecoration:'none',color:'black'}} to ='/chandanadmin/user' >
            <Button fullWidth style={{display:'flex',justifyContent:'flex-start'}}><GroupIcon/>&nbsp;&nbsp;Users</Button>
            </Link>
           
            <Button fullWidth onClick={()=>handleLogout()} style={{display:'flex',justifyContent:'flex-start'}}><LogoutIcon/>&nbsp;&nbsp;Logout</Button>
         
          
        </List>
      </Grid>
    </Grid>
  );
}
