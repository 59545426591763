import React from "react";
import { useEffect, useState } from "react";
import { postData } from "../../Services/NodeServices";
import { useParams } from "react-router";
import { ProductsDetailComponent } from "./ProductsDetailComponent";
import MainBar from "./MainBar";
import SearchBar from "./SearchBar";
import DownBox from "./DownBox";
import { Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import FilterCompomnent from "./FilterComponent";
import { serverURL } from "../../Services/NodeServices";
import { useLocation } from "react-router";
import LineImage from "../../Assets/line.jpg";

export default function ProductList() {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(900));

    const [getProduct, setProduct] = useState([])
    var location = useLocation()
    const { id, icon } = useParams()
    const id1 = location.state.id
    const FetchProductBySubcategory = async (id) => {

        var body = { subcategoryid: id }
        var result = await postData('userinterface/fetch_product_by_subcategory', body)
        setProduct(result.data)
        console.log(result.data)
    }

    useEffect(() => {
        FetchProductBySubcategory(id)
    }, [id]);

    return (<div >
        <SearchBar />
        <MainBar />
        <Container maxWidth={"xl"}>
            <div style={{ display: 'flex', marginTop: 5, flexDirection: "column", flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '100%', height: 'auto' }}>
                    <img src={`${serverURL}/images/${icon}`} style={{ width: '100%', height: mobile ? 200 : 500 }} />
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: 60, flexDirection: "column", flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '100%', height: 'auto' }}>
                    <img src={LineImage} style={{ width: '100%', height: mobile ? 15 : 40 }} />
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: 40, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                <ProductsDetailComponent data={getProduct} />
            </div>
            <div style={{ display: 'flex', marginTop: 60, flexDirection: "column", flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '100%', height: 'auto' }}>
                    <img src={LineImage} style={{ width: '100%', height: mobile ? 15 : 40 }} />
                </div>
            </div>
        </Container>
        <div
            style={{
                marginTop: 50,
                background: '#ffd1d9',
            }}
        >
            <Container maxWidth={"xl"}>
                <DownBox />
            </Container>
        </div>
    </div>)

}