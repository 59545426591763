import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LiveRates = () => {
    const [goldRate, setGoldRate] = useState(null);
    const [silverRate, setSilverRate] = useState(null);
  
    useEffect(() => {
      const fetchRates = async () => {
        try {
          const response = await axios.get(
            'https://www.goldapi.io/api/XAU/INR',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': 'goldapi-1ck7ebrrljoks6p2-io',
                  },
            }
          );
         console.log(response.data.price)
         setGoldRate(parseInt(response.data.price/2.66))
        
        } catch (error) {
          console.error('Error fetching rates:', error);
        }
      };
  fetchRates()
      
    }, []);
    useEffect(() => {
      const fetchSilverRates = async () => {
        try {
          const response = await axios.get(
            'https://www.goldapi.io/api/XAG/INR',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': 'goldapi-1ck7ebrrljoks6p2-io',
                  },
            }
          );
         console.log(response.data.price)
         setSilverRate(parseInt(response.data.price*35.274))
        
        } catch (error) {
          console.error('Error fetching rates:', error);
        }
      };
  fetchSilverRates()
      
    }, []);
  
    return (
      <div>
        <h2>Gold Rate: {goldRate}</h2>
        <h2>Silver Rate: {silverRate}</h2>
      </div>
    );
  
}

export default LiveRates
