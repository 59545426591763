import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { styled, alpha } from "@mui/material/styles";
import { Container, useMediaQuery, useTheme,TextField } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import UserComponentpannel from "./UserComponentPannel.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { postData } from "../../Services/NodeServices.js";
import { useContext } from "react";
import { SessionContext } from "../../Services/SessionContext";
import { Button } from "@mui/material";
import MobileMenuBar from "./MobileMenuBar.js";
import { InputAdornment } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Swal from "sweetalert2";
import logo from '../../Assets/logo192.png'
const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    
   
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
}));

export default function SearchBar() {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down(1000));

    var navigate = useNavigate();
    const { cart, setCart } = useContext(SessionContext);
    const { session, setSession } = useContext(SessionContext);
    const Token = window.localStorage.getItem("Token");
    const User = window.localStorage.getItem("UserNumber")==null?window.localStorage.getItem("UserMail"): window.localStorage.getItem("UserNumber")
    const cartData = useSelector((state) => state.cart);
    const [keyword, setKeyword] = React.useState(0);

    const func = async () => {
        var formdata = new FormData();
        formdata.append("mobile", User);
        var response = await postData("cart/getAllProducts", formdata, true);
        setCart(response.products.length);
    };

    React.useEffect(
        function () {
            func();
        },
        [cart]
    );

    const handleMenuBar = () => {
        return (
            <>
                <MobileMenuBar />
            </>
        )
    }

    const handleLogout = () => {
        window.localStorage.removeItem("Token");
        window.localStorage.removeItem("UserNumber");
        window.localStorage.removeItem("UserMail")
        window.location.reload();
    };
    const handleCart=()=>{
        if(Token)
        {
            navigate("/cart")
        }
        else
        {
            Swal.fire({
                icon: "error",
                title: "Login",
                title: "Please Log in to view your cart",
                confirmButtonColor:'#FF94CA'
            });
        }
    }
    const handleSearch=async(event)=>{
        
        navigate('/productlist3',{state:{keywords:keyword}} )
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" style={{ backgroundColor: "#FFD1D9" }}>
                <Container maxWidth="xl">
                    <Toolbar>
                        {matches ? (
                            <>
                                <IconButton
                                    onClick={handleMenuBar}
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2,ml:-10,backgroundColor:'#ffd77f' }}
                                >
                                    {/* <MenuIcon sx={{ color: '#bd147c', fontSize: 30 }} /> */}
                                </IconButton>
                                {<MobileMenuBar />}
                            </>
                        ) : (
                            <></>
                        )}
                                                <img src={logo}  onClick={() => navigate("/")} width={80} style={{borderRadius:30,marginLeft:-10,marginRight:4}}/> 
                        <div
                            style={{ fontSize: "18",color:'#d24a61', fontWeight: "bolder", width: "20%", display: matches ? "none" : "block",fontFamily:'initial' }}
                            onClick={() => navigate("/")}
                        >
                            Chandan Jewellers
                        </div>
                        <Search style={{ width: matches?"85%":"50%" }}>
                        <TextField
                label="Search"
                
                fullWidth
                onChange={(e) => setKeyword(e.target.value)}
                sx={{marginTop:1,marginBottom:1,  
                    '& .MuiOutlinedInput-root': {'&:hover fieldset': {
                        borderColor: '#D24A61', // Outline color when hovering
                      },
                        
                        '&.Mui-focused fieldset': {
                          borderColor: '#D24A61', // Outline color when focused
                          
                        },
                      },
               
                "& label": { paddingLeft: (theme) => theme.spacing(2),color:'#D24A61' , '&.Mui-focused': {
                    color:'#D24A61' // Change label color when focused
                  },},
                "& input": { paddingLeft: (theme) => theme.spacing(3.5),color:'#D24A61' },
               
                "& fieldset": {
                  paddingLeft: (theme) => theme.spacing(2.5),
                  borderRadius: "20px",
                  
                },
                }}
                InputProps={{
                    style:{
                        borderColor: 'red',
                    },
                   
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        variant="contained"
                        disabled={keyword==''?true:false}
                        onClick={handleSearch}
                        style={{
                          border: 0,
                          borderRadius: 20,
                          zIndex:1,
                         color:'#FFD1D9',
                         backgroundColor:'#D24A61',
                          cursor: "pointer",
                        }}
                      >
                        <SearchRoundedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
                        </Search>
                        <div
                            style={{ display: "flex", justifyContent: "right",marginLeft:15, width: "15%"}}
                        >
                            <ShoppingCartIcon
                                style={{ cursor: "pointer", color: "#D24A61", fontSize: matches ? 30 : 35 }}
                                onClick={()=>handleCart()}
                            
                            />
                            <Typography sx={{ textAlign: "center", fontSize: matches ? 8 : 10,color:"#D24A61" }}>
                                {`${cart}`}
                            </Typography>
                        </div>
                        <div style={{ width: "5%",display:matches?"none":"block" }}>

                        </div>
                        <div style={{display:matches?"none":"block"}}>
                            <UserComponentpannel />
                            <div style={{ fontSize: 9, textAlign: "center",color:'#D24A61', display: matches ? "none" : "block" }}>{Token ? "" : "Sign up"}</div>
                        </div>
                        <div style={{display:matches?"none":"block"}} >
                            {Token ? (
                                <Button sx={{ fontSize: 12, color: "#FFD1D9", background: "#D24A61", marginLeft: matches?10:2, width: 80, "&:hover": { background: "#fff", color: "#D24A61", border: "1px solid #ffd77f" } }} onClick={() => handleLogout()}>Log Out</Button>
                            ) : (
                                ""
                            )}
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
}
