import React from 'react';
import axios from 'axios';
import { postData } from '../../Services/NodeServices';
const Clickpay = () => {
  const apiUrl = 'http://localhost:5000/api/proxy'; // Your backend endpoint
  const requestBody ={
    "merchantId": "INDIABUZZONLINE",
    "merchantTransactionId": "4s6h7sdfg8",
    "merchantUserId": "MUID123",
    "amount": 100,
    "redirectUrl": "https://webhook.site/1aa944c0-8c4d-48e0-a4fa-275c490f3c0b",
    "redirectMode": "POST",
    "callbackUrl": "https://webhook.site/1aa944c0-8c4d-48e0-a4fa-275c490f3c0b",
    "mobileNumber": "7225051627",
    "paymentInstrument": {
      "type": "PAY_PAGE"
    }
  }
  const handleClick = async() => {
   const response=await postData('api/proxy',requestBody)
   console.log("bodyyyyyy",response.data.instrumentResponse.redirectInfo.url)
   window.open(response.data.instrumentResponse.redirectInfo.url)
  
  };

  return (
    <button onClick={handleClick}>Click to Pay</button>
  );
};

export default Clickpay;
