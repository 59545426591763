import {
  Box,
  Typography,
  Grid,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Image from "../../Assets/About_Image.png";
import '../outline.css'

export default function About() {
  const theme = useTheme();
  const matches1 = useMediaQuery(theme.breakpoints.down(900));
  const matches2 = useMediaQuery(theme.breakpoints.down(1200));
  return (
    <Box sx={{ background: "#ffd1d9" }}>
      <Container maxWidth={"xl"}>
        <Grid sx={{ padding: "30px 50px" }} container spacing={2}>
          <Grid item xs={12} md={6}>
            <img
              src={Image}
              width="80%"
              style={{
                marginLeft: 20,
                marginTop: 26,
                display: matches1 ? "none" : "block",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
            class="fontLink"
              style={{
                fontSize: 40,
                textAlign: "center",
                fontWeight: "bold",
                color: "#d24a61",
                mb: 5,
              }}
            >
              ABOUT US
            </Typography>
            <img
              src={Image}
              width="120%"
              style={{
                marginLeft: matches2?-50:-25,
                marginTop: 15,
                marginBottom: 15,
                display: matches1 ? "block" : "none",
              }}
            />
            <Typography
              sx={{
                display: matches1 ? "none" : "block",
                fontSize: 16,
                textAlign: "justify",
                fontWeight: "medium",
                color: "#AF4743",
                wordSpacing: 3,
                marginBottom: 8,
              }}
            >
              Chandan Jewellers is a renowned jewellery store located in the
              historic city of Gwalior, India. Established in the year 1975, the
              store has been offering a wide range of exquisite jewellery pieces
              to its customers for over four decades. The store specializes in
              crafting stunning pieces of gold, diamond, and silver jewellery
              that are unique and timeless. They also offer a beautiful
              selection of traditional Indian jewellery, including Kundan,
              Meenakari, and Polki designs. Chandan Jewellers prides itself on
              its quality craftsmanship, attention to detail, and personalized
              customer service. Their experienced team of designers and
              craftsmen work closely with customers to create bespoke jewellery
              that reflects their personal style and taste. Whether you're
              looking for a special piece for a wedding, anniversary, or any
              other occasion, Chandan Jewellers has something to suit every
              taste and budget. Visit their store in Gwalior today to discover
              their stunning collection of jewellery and experience their
              exceptional service.
            </Typography>
            <Typography
              sx={{
                display: matches1 ? "block" : "none",
                fontSize: 16,
                textAlign: "justify",
                fontWeight: "medium",
                color: "#AF4743",
               
                marginBottom: 8,
              }}
            >
              Chandan Jewellers, in Gwalior, India, offers exquisite gold,
              diamond, and silver jewellery. Established in 1975, the store also
              provides traditional Indian Kundan, Meenakari, and Polki designs.
              Their experienced team offers personalized customer service and
              bespoke jewellery. Chandan Jewellers is renowned for its quality
              craftsmanship and attention to detail, with pieces that reflect
              personal style and taste. They cater to every budget and occasion.
              Visit the store to explore their beautiful collection and
              exceptional service.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
