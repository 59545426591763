import React from "react";
import { Grid, TextField, Button, Container, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import SearchBar from "./SearchBar";
import MainBar from "./MainBar";
import DownBox from "./DownBox";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageSlider from "./ImageSlider";
import { useLocation } from "react-router";
import { serverURL } from "../../Services/NodeServices";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { SessionContext } from "../../Services/SessionContext";
import { postData } from "../../Services/NodeServices";
import { useEffect } from "react";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import OtpComponent from "./OtpComponent";
import OtpGenerator from "./OtpGenerator";
import { Divider } from "@material-ui/core";
import { useNavigate } from "react-router";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CardImage from "../../Assets/chandan jewellers.jpg";
import GoogleLogin from "react-google-login";
export default function SetProductDetails(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(900));

    const { cart, setCart } = useContext(SessionContext);
    var dispatch = useDispatch();
    var navigate = useNavigate();
    const Token = window.localStorage.getItem("Token");
    const User = window.localStorage.getItem("UserNumber")==null?window.localStorage.getItem("UserMail"): window.localStorage.getItem("UserNumber")
    var location = useLocation();
    var product = location.state.product;

    const [open, setopen] = useState();
    const [openOtp, setOpenOtp] = useState(false);
    const [button, setButton] = useState(false);
    const [otp, setOtp] = useState();
    const [enteredOtp, setEnteredOtp] = useState()
    const [mobileNumber, setMobileNumber] = useState("");
    const [message, setMessage] = useState("");

    const handleOpen = () => {
        setopen(true);
    };
    const handleClose = () => {
        setopen(false);
    };

    const hadleopenotpdailog = () => {
        setopen(false);
        setOpenOtp(true);

        var otpval = OtpGenerator();
        setOtp(otpval);
        alert(otpval);
    };
    const handleotpdailogClose = () => {
        setOpenOtp(false);
    };
    const onSuccess=async(res)=>{
        console.log(res.profileObj.email)
        setopen(false)
        var formdata = new FormData();
        formdata.append("email", res.profileObj.email);
       
    
        var response = await postData("users/addusersbymail", formdata, true);
        console.log(response)
        if(response.result==true || response.result=='exist')
        { 
          window.localStorage.setItem("Token",true)
          console.log(response.mail)
          window.localStorage.setItem("UserMail",response.mail)
          Swal.fire({
          icon: 'success',
          title: 'successfully Log In',
          confirmButtonColor:"#d24a61"
         
        })
        navigate("/details",{state:{subTotal:product.price,discount:(product.price-product.offerprice),totalPrice:product.offerprice,products:[{productId:product._id,count:1,_id:''}]}})
    
        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Try Again',
          
          })
    
        }
      }
      const onFailure=(res)=>{
        console.log("Login fail",res)
      }
    const ShowSignUpDailog = () => {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Grid item xs={12}>
                        <img src={CardImage} width="100%" height="100%" />
                    </Grid>
                    <Grid xs={12} style={{ fontSize: 20, fontWeight: "bold" }}>
                        SignUP
                    </Grid>
                    <Grid item xs={12}>
                        <hr></hr>
                        <TextField
                            onChange={(event) => setMobileNumber(event.target.value)}
                            fullWidth
                            id="standard-basic"
                            label=" your mobile no"
                            variant="standard"
                            type="number"
                        />
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: "5%" }}>
                        <Button
                            onClick={hadleopenotpdailog}
                            variant="contained"
                            fullWidth
                            style={{ backgroundColor: "green", color: "white" }}
                        >
                            GET AN OTP
                        </Button>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:'5%'}}>
          {/* <Button onClick={() => login()}>
  Sign in with Google 🚀{' '}
</Button> */}
<GoogleLogin
clientId={"1035996653814-37vdbrh994jsq37688frv3h2uoj3pf9k.apps.googleusercontent.com"}
  onSuccess={onSuccess}
  onFailure={onFailure}
  buttonText="Login"
 
/>
</Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            fontSize: 15,
                            fontWeight: "bold",
                            marginTop: "4%",
                            cursor: "pointer",
                        }}
                    >
                        Already have an account..?
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const chkOtp = async (event) => {
        if (otp == event.target.value) {
            setOpenOtp(false);
            var formdata = new FormData();
            formdata.append("mobile", mobileNumber);

            var response = await postData("users/addusers", formdata, true);
            if (response.result == true || response.result == "exist") {
                window.localStorage.setItem("Token", true);
                console.log(response.mobileNumber);
                window.localStorage.setItem("UserNumber", response.mobileNumber);
                Swal.fire({
                    icon: "success",
                    title: "successfully Log In",
                });
                navigate("/details",{state:{subTotal:product.price,discount:(product.price-product.offerprice),totalPrice:product.offerprice,products:[{productId:product._id,count:1,_id:''}]}})
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Try Again",
                });
            }
        } else {
            setMessage("Invalid Otp");
        }
    };

    const ShowOtpDailog = () => {
        return (
            <div>
                <Dialog
                    open={openOtp}
                    keepMounted
                    onClose={handleotpdailogClose}
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>
                        <div>Confirm your number</div>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <DialogContentText>
                            <div>
                                Enter the code we have sent via SMS to +91{mobileNumber}
                            </div>
                        </DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {/* <OtpComponent
                                    value=""
                                    onChange={(value) => {
                                        chkOtp(value);
                                    }}
                                /> */}

                                <TextField value={enteredOtp} onChange={(event) => chkOtp(event)} />
                            </Grid>
                            <Grid item xs={12}>
                                <div>Haven't recieved a code? More Options</div>
                            </Grid>
                            <Grid item xs={12}>
                                <div>{message}</div>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleotpdailogClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const func = async () => {
        var formdata = new FormData();
        formdata.append("mobile", User);
        var response = await postData("cart/getAllProducts", formdata, true);

        setCart(response.products.length);
        const isProductInCart = response.products.some(
            (products) => products.productId == product._id
        );
        
        if (isProductInCart) {
           
            setButton(true);
        } else {
           
        }
    };
    const handleShadow = async () => {
        if (Token) {
            var formdata = new FormData();
            formdata.append("mobile", User);
            formdata.append("productId", product._id);
            formdata.append("count", 1);
            var response = await postData("cart/add", formdata, true);
            if (response.result == true) {
                window.location.reload();
                func();
            }
        } else {
            handleOpen();
        }
    };
    useEffect(() => {
        func();
    }, []);

    const handleBuyNow =()=>{ 
        
       if(Token)
        { navigate("/details",{state:{subTotal:product.price,discount:(product.price-product.offerprice),totalPrice:product.offerprice,products:[{productId:product._id,count:1,_id:''}]}})}
        else{
            handleOpen();
        }
    }

    const showDetails = () => {
        return (
            <Grid container>
                <Grid
                    item
                    xs={12} lg={5}
                    style={{
                        marginTop: "3%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ImageSlider pictures={product.picture} />
                </Grid>
                <Grid
                    item
                    xs={12} lg={6}
                    style={{
                        marginTop: "3%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                    }}
                >
                    <Grid container spacing={0}>

                        <Grid item xs={12} style={{ fontSize: mobile ? 22 : 34, fontWeight: 600,color:'#d24a61', marginTop: 7 }}>
                            {product.productname}
                        </Grid>
                        <Grid item xs={12}
                            style={{
                                fontSize: mobile ? 16 : 20,
                                fontWeight: 600,
                                marginTop: "3%",
                                textDecoration: "line-through",
                                color: "red",
                            }}
                        >
                            PRICE &nbsp;: &nbsp; Rs  &nbsp;{product.price}
                        </Grid>
                        <Grid item xs={12}
                            style={{
                                fontSize: mobile ? 16 : 20,
                                fontWeight: 600,
                                marginTop: "3%",
                                color: "green",
                            }}
                        >
                            Exclusive PRICE &nbsp;: &nbsp; Rs  &nbsp;{product.offerprice}
                        </Grid>
                        <Grid item xs={12} sx={{ mt: "3%", fontSize: mobile ? 14 : 16 }}>inclusive of all Taxes + Free Shipping</Grid>
                    </Grid>

                    <Grid spacing={1} container sx={{ mt: "2%" }}>
                        <Grid item xs={12}>
                            {button ? (
                                <Button
                                    sx={{
                                        backgroundColor: "#ffffff", color: "#d24a61", border: "2px solid #d24a61", marginTop: 2, fontSize: 20, "&:hover": {
                                            background: "#d24a61",
                                            border: "2px solid #d24a61",
                                            fontSize: { lg: 20 },
                                            color: "#ffffff",
                                        }
                                    }}
                                    variant="contained"
                                    fullWidth
                                    onClick={() => navigate("/cart")}
                                >
                                    Go To Cart
                                </Button>
                            ) : (
                                <Button
                                    sx={{
                                        backgroundColor: "#ffffff", color: "#d24a61", border: "2px solid #d24a61", marginTop: 2, fontSize: 20, "&:hover": {
                                            background: "#d24a61",
                                            border: "2px solid #d24a61",
                                            fontSize: { lg: 20 },
                                            color: "#ffffff",
                                        }
                                    }}
                                    variant="contained"
                                    fullWidth
                                    onClick={() => handleShadow()}
                                >
                                    ADD To Cart
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                sx={{
                                    background: "#d24a61",
                                    fontSize: 20,
                                    color: "#ffffff",
                                    border: "2px solid #d24a61",
                                    width: "100%",
                                    "&:hover": {
                                        background: "#fff",
                                        border: "2px solid #d24a61",
                                        fontSize: 20,
                                        color: "#d24a61",
                                    },
                                    mt: 2
                                }}
                                variant="contained"
                                fullWidth
                                onClick={()=>handleBuyNow()}
                            >
                                BUY NOW
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: 22, fontWeight: "bold", color: "lightgrey" }}>DELIVERY OPTIONS</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} style={{ marginTop: 10 }}>
                                <Grid
                                    xs={12} lg={6}
                                    style={{ paddingLeft: 20, fontSize: mobile ? 16 : 20, fontWeight: "bold", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}
                                >
                                    <img src={require("../../Assets/cod.png")} width={mobile ? 18 : 25} style={{ marginRight: mobile ? 18 : 14, marginLeft: mobile ? -5 : 0 }} />
                                    Cash On Delivery
                                </Grid>
                                <Grid
                                    xs={12} lg={6}
                                    style={{ marginTop: mobile ? 8 : 0, paddingLeft: mobile ? 15 : 20, fontSize: mobile ? 16 : 20, fontWeight: "bold", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}
                                >
                                    <LocalShippingIcon sx={{ fontSize: mobile ? 20 : 30, marginRight: 2 }} />
                                    Free Express Shipping
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const showproductDetail = () => {
        return (
            <div>
                <div
                    style={{
                        marginTop: mobile ? "30%" : "5%",
                        justifyContent: "center",
                        textAlign: "center",
                    }}
                >
                    <div
                        style={{
                            fontSize: mobile ? 20 : 30,
                            fontWeight: 700,
                            marginBottom: 40,
                            display: "flex",
                            textAlign: "center",
                            justifyContent: "center",
                            color:"#d24a61"
                        }}
                    >
                        {" "}
                        Product Details
                    </div>
                </div>
                <Grid
                    style={{
                        display: "flex",
                        alignItem: "center",
                        justifyContent: "center",
                        padding: mobile ? "0px" : "10px 120px"
                    }}
                    container
                    spacing={2}
                >
                    <Grid item xs={12} lg={6}>
                        <div style={{ height: 300, background: "#ffd1d9", borderRadius: 8, padding: 25, borderRadius: 8 }}>
                            <div
                                style={{
                                    fontWeight: 700,
                                    lineHeight: "35px",
                                    display: "flex",
                                    textAlign: "center",
                                    fontSize: 20,
                                    marginBottom: 8,
                                    padding: "0px 20px"
                                }}
                            >
                                Product Description
                            </div>
                            <ul style={{ padding: "8px 20px", fontSize: mobile ? 13 : 18, textAlign: "justify" }}>
                                <li style={{ fontSize: mobile ? 13 : 18, textAlign: "justify", fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>{product.description1}</li>
                                <li style={{ fontSize: mobile ? 13 : 18, textAlign: "justify", fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>{product.description2}</li>
                                <li style={{ fontSize: mobile ? 13 : 18, textAlign: "justify", fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>{product.description3}</li>
                                <li style={{ fontSize: mobile ? 13 : 18, textAlign: "justify", fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>{product.description4}</li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <div style={{ height: 300, background: "#ffd1d9", borderRadius: 8, padding: 25 }}>
                            <div
                                style={{
                                    fontWeight: 700,
                                    lineHeight: "35px",
                                    display: "flex",
                                    textAlign: "center",
                                    fontSize: 20,
                                    marginBottom: 8,
                                    padding: "0px 20px"
                                }}
                            >
                                Delivery & Return Policy
                            </div >
                            <Typography sx={{ padding: "8px 20px", fontSize: mobile ? 13 : 18, textAlign: "justify" }}>
                                We provide free shipping on all orders. Pay online to avoid
                                charges of ₹50/product applicable on COD orders. The return or
                                exchange can be done within 15 days after delivery. Every delivery
                                from Beyoung is processed under excellent condition and in the
                                fastest time possible. For our beloved customer’s care, we give
                                contactless delivery. Refer to FAQ for more information.
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    };

    return (
        <Grid sx={{ overflowX: "hidden" }}>
            <SearchBar />
            <MainBar />
            <Container maxWidth={"xl"}>
                <div style={{ marginBottom: 20 }}>
                    {showDetails()}
                </div>
                <div style={{ marginTop: "5%" }}>
                    {showproductDetail()}
                </div>
                <div>
                    {ShowSignUpDailog()}
                    {ShowOtpDailog()}
                </div>
            </Container>
            <div
                style={{
                    marginTop: 50,
                    background: '#ffd1d9',
                }}
            >
                <Container maxWidth={"xl"}>
                    <DownBox />
                </Container>
            </div>
        </Grid>
    );
}
