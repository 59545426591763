import { useEffect,useState } from "react";
import MaterialTable from "@material-table/core";
import { getData, postData, serverURL } from "../Services/NodeServices";
// import React from "react";
import { Button,Grid,Avatar,TextField } from "@material-ui/core";
import { useStyles } from "./DisplayAllOrderCss";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";

export default function DisplayAllUsers(props)
{ const classes=useStyles()
  const navigate=useNavigate()
  const[categoryID,setCategoryID]=useState(' ') 
  const[userID,setUserID]=useState(' ')
  const[customerName,setCustomerName]=useState('') 
  const[customerNumber,setCustomerNumber]=useState('') 
  const[address,setAddress]=useState('') 
  const[city,setCity]=useState('') 
  const[district,setDistrict]=useState('') 
  const[state,setState]=useState('') 
  const[pincode,setPincode]=useState('') 
  

    
   
  const [open,setOpen]=useState(false)
  const [btnStatus,setBtnStatus]=useState(false)

  const [users,setUsers]=useState([ ])
  const FetchAllUsers=async()=>{
 var data= await getData('users/display_all_users')

    setUsers(data.data)
  }

  useEffect(function(){
    FetchAllUsers()
  },[])

  
     
  const handleOpen=(rowData)=>{
    setUserID(rowData._id)
    
    if(rowData.name==undefined)
    {
        setCustomerName('No Data Available')
    }
    else
    {
        setCustomerName(rowData.name)
    }
    setCustomerNumber(rowData.mobile)
    if(rowData.address==undefined)
    {
        setAddress('No Data Available')
    }
    else
    {
        setAddress(rowData.address)
    }

    if(rowData.city==undefined)
    {
        setCity('No Data Available')
    }
    else
    {
        setCity(rowData.city)
    }

    if(rowData.district==undefined)
    {
        setDistrict('No Data Available')
    }
    else
    {
        setDistrict(rowData.district)
    }

    if(rowData.state==undefined)
    {
        setState('No Data Available')
    }
    else
    {
        setState(rowData.state)
    }
    
    if(rowData.pincode==undefined)
    {
        setPincode('No Data Available')
    }
    else
    {
        setPincode(rowData.pincode)
    }
    
    setOpen(true)
  }
  const handleClose=()=>{
    setOpen(false)
  }
  
  

 


 const  showUsers=()=>{
        return(<div>
           <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        
        <DialogContent>

       
            
               <Grid container spacing={2}>
                <Grid item xs={12} style={{ fontSize:20,
        fontWeight:'bolder',}}>
               User_details
                </Grid>
                <Grid item xs={12} >
                   Customer Id : {userID}
                </Grid>
                
                <Grid item xs={12} >
                   Customer Name : {customerName}
                </Grid>
                <Grid item xs={12} >
                   Customer Phone Name : {customerNumber}
                </Grid>
                
                <Grid item xs={12} >
                   Address : {address}
                </Grid>
                <Grid item xs={12} >
                   City : {city}
                </Grid>
                <Grid item xs={12} >
                   District : {district}
                </Grid>
                <Grid item xs={12} >
                   State : {state}
                </Grid>
                <Grid item xs={12} >
                   Pincode : {pincode}
                </Grid>

                
                
                
                 
               </Grid>
          

             
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
    
        </DialogActions>
      </Dialog>
  </div>

        )

      }


        function DisplayUsers() {
            return (
             
              <MaterialTable
                title="List of "
                columns={[
                  { title: 'User-ID', field: '_id' },
                  { title: 'Mobile Number / Email Id ', field: 'email' },
                  { title: 'More Details',  render:(rowData)=><div><Button variant='contained' onClick={()=>handleOpen(rowData)}>Show</Button></div> },
                 
                 
                ]}
                data={users}        
              
              />
          
            )
          }
          
return(
  <div style={{ height:'100vh',
    background:'#f5f6fa',
    justifyContent:'center',
    display:'flex'}}>
      <div style={{borderRadius:20,
        width:'100%',
        height:'40%',
        background:'white ',
        marginTop:'3%'}}>
        {DisplayUsers()}
    </div>
         {showUsers()}
    </div>
)
   
}