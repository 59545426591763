import React from "react";
import { useEffect, useState } from "react";
import { postData } from "../../Services/NodeServices";
import { useParams } from "react-router";
import { ProductsDetailComponent } from "./ProductsDetailComponent";
import MainBar from "./MainBar";
import SearchBar from "./SearchBar";
import DownBox from "./DownBox";
import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import FilterCompomnent from "./FilterComponent";
import { serverURL } from "../../Services/NodeServices";
import { useLocation } from "react-router";
import LineImage from "../../Assets/line.jpg";
import bg from "../../Assets/Category Banner.jpg";
import mobileBG from "../../Assets/Category_Banner_mobile.jpg";

export default function ProductList2() {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(900));
    const [getProduct, setProduct] = useState([])
    var location = useLocation()
    const { id, icon } = useParams()

    const categoryName = location.state.categoryname;
    const FetchProductBySubcategory = async (id) => {

        var body = { categoryid: id }
        var result = await postData('userinterface/fetch_product_by_category', body)
        setProduct(result.data)
        console.log(result.data)
    }

    useEffect(() => {
        FetchProductBySubcategory(id)
    }, [id]);
    return (<div >
        <SearchBar />
        <MainBar />
        <Container maxWidth={"xl"}>
            <div style={{  backgroundImage:mobile?`url('${mobileBG}')`:`url('${bg}')`,backgroundSize:'100% 100%',backgroundRepeat:'no-repeat',display: 'flex', flexDirection: "column", padding: 1, margin:mobile?"10px 0px": "10px 20px 5px", flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center',height:mobile?400:500 }}>
                <Typography sx={{fontSize:mobile?50:140,fontWeight:700,fontFamily: 'Tangerine',color:"#F9F2B6"}}>{categoryName}</Typography>
            </div>
            <div style={{ display: 'flex', marginTop: 30,marginBottom:15, flexDirection: "column", flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '100%', height: 'auto' }}>
                    <img src={LineImage} style={{ width: '100%', height: mobile ? 15 : 40 }} />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                <ProductsDetailComponent data={getProduct} />
            </div>
            <div style={{ display: 'flex', marginTop: 60, flexDirection: "column", flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '100%', height: 'auto' }}>
                    <img src={LineImage} style={{ width: '100%', height: mobile ? 15 : 40 }} />
                </div>
            </div>
        </Container>
        <div
            style={{
                marginTop: 50,
                background: '#ffd1d9',
            }}
        >
            <Container maxWidth={"xl"}>
                <DownBox />
            </Container>
        </div>
    </div>)

}