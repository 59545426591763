import * as React from "react";
import SideList from "./SideList";
import AdminAppBar from "./AdminAppBar";
import Category from "./Category";
import DisplayAllCategory from "./DisplayAllCategory";
import SubCategory from "./SubCategory";
import DisplaySubCategory from "./DisplaySubCategory";
import DisplayProduct from "./Products";
import DisplayAllProduct from "./DisplayProduct";

import BannerImages from "./BannerImages";
import { Routes, Route } from "react-router";
import { height } from "@mui/system";
import DisplayAllOrder from "./DisplayAllOrder";
import DisplayAllUsers from "./DisplayAllUsers";
// import { useTheme } from "@mui/material/styles";
// import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router";

import { Grid } from "@mui/material";

export default function Dashboard(props) {
const navigate=useNavigate()
    // const theme = useTheme();
    // const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const admin = localStorage.getItem("Admin")


    React.useEffect(()=>{
        if(!admin)
        {
            navigate('/adminlogin')
        }

    },[])

    return (
        <Grid style={{ display: "flex", background: "#f5f6fa", flexDirection: 'column', width:'100%' }}>
            <AdminAppBar />
            <Grid container spacing={2} sx={{width:"100%",display:'flex',justifyContent:{xs:'center',sm:'center',md:'flex-start'}}}>
                <Grid xs={12} md={3}  sx={{ width:'25%',display:'flex',justifyContent:{xs:'center',sm:'center',md:'flex-start',marginLeft:10} }}>
                    <SideList />
                </Grid>
                <Grid xs={10} md={8} sx={{marginLeft:10,marginTop:2, height: "100vh",width:{xs:'100%',md:'75%'} }}>
                    <Routes>
                        <Route element={<Category />} path="/category" />
                        <Route element={<DisplayAllCategory />} path="/displayallcategory" />

                        <Route element={<SubCategory />} path="/subcategory" />
                        <Route
                            element={<DisplaySubCategory />}
                            path="/displaysubcategory"
                        />
                        <Route element={<DisplayProduct />} path="/product" />
                        <Route element={<DisplayAllProduct />} path="/displayallproduct" />

                        <Route element={<BannerImages />} path="/bannerimage" />
                        <Route element={<DisplayAllOrder/>} path="/order" />
                        <Route element={<DisplayAllUsers/>} path="/user" />
                    </Routes>
                </Grid>
            </Grid>
        </Grid>
    );
}
