import Dashboard from "./Components/Administrator/Dashboard";
import AdminLogin from "./Components/Administrator/AdminLogin";
import Home from "./Components/UserInterface/Home";
import BannerImages from "./Components/Administrator/BannerImages";
import SearchBar from "./Components/UserInterface/UserComponents/SearchBar";
import FilterCompomnent from "./Components/UserInterface/UserComponents/FilterComponent";
import SetProductDetails from "./Components/UserInterface/UserComponents/SetProductDetails";
import { SessionContext } from "./Components/Services/SessionContext";
import React, { useEffect } from "react";
import PaymentForm from "./Components/UserInterface/test";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProductList from "./Components/UserInterface/UserComponents/ProductList";
import CartPage from "./Components/UserInterface/UserComponents/CartPage";
import DeliverDetails from "./Components/UserInterface/UserComponents/DeliverDetails";
import ProductList2 from "./Components/UserInterface/UserComponents/ProductList2";
import ProductList3 from "./Components/UserInterface/UserComponents/ProductList3";
import PaymentGateway from "./Components/UserInterface/PaymentGateWay/PaymentGateway";
import { HashRouter } from "react-router-dom";
import { Notifications } from 'react-push-notification';
import addNotification from "react-push-notification";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { gapi } from "gapi-script";
import Clickpay from "./Components/UserInterface/PaymentGateWay/Clickpay";
import LiveRates from "./Components/UserInterface/UserComponents/LiveRates";
const clientId="1035996653814-37vdbrh994jsq37688frv3h2uoj3pf9k.apps.googleusercontent.com"
function App() {
  const [session, setSession] = React.useState(false);
  const [cart, setCart] = React.useState(0);
  const [notification, setNotification] = React.useState(false);
  const admin = localStorage.getItem("Admin")

  useEffect(()=>{
    function start(){
      gapi.client.init({
        clientId:clientId,
        scope:""
      })
    }
    gapi.load('client:auth2',start )
  })

  return (
    <SessionContext.Provider value={{ session, setSession ,cart,setCart,notification,setNotification}}>
    <GoogleOAuthProvider clientId="1035996653814-37vdbrh994jsq37688frv3h2uoj3pf9k.apps.googleusercontent.com">    <div >
    <Notifications />
      <HashRouter>
        <Routes>
          <Route element={<Dashboard />} path="/chandanadmin/*" />
          <Route element={<AdminLogin />} path="/adminlogin" />
          <Route element={<Home />} path="/" />
          <Route element={<ProductList/>} path="/productlist/:id/:icon" />
          <Route element={<ProductList2/>} path="/productlist2/:id/:icon" />
          <Route element={<ProductList3/>} path="/productlist3" />
          <Route element={<PaymentGateway/>} path="/paymentgateway" />
          <Route element={<FilterCompomnent/>} path="/filter" />
          <Route element={<SetProductDetails/>} path="/setproduct" />
          <Route element={<CartPage/>} path="/cart" />
          <Route element={<DeliverDetails/>} path="/details" />
          <Route element={<PaymentForm/>} path="/paymentform" />
          <Route element={<Clickpay/>} path="/clickpay" />
          <Route element={<LiveRates/>} path="/liverates" />
        </Routes>
      </HashRouter>
    </div>
    </GoogleOAuthProvider>

    </SessionContext.Provider>
  );
}

export default App;
