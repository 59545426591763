import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ListItem } from '@mui/material';
import { getData, postData } from '../../Services/NodeServices';
import { useNavigate } from 'react-router';
import MenuIcon from "@mui/icons-material/Menu";
import arrow from "../../Assets/next.png";
import cardImage from "../../Assets/chandan jewellers.jpg";
import UserComponentpannel from "../UserComponents/UserComponentPannel.js";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { SessionContext } from "../../Services/SessionContext";

export default function MobileMenuBar() {
    const navigate = useNavigate();
    const [getCategory, setCategory] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({ left: false });
    const [selectedCategory, setSelectedCategory] = useState('')
    const Token = window.localStorage.getItem("Token");
    const User = window.localStorage.getItem("UserNumber")==null?window.localStorage.getItem("UserMail"): window.localStorage.getItem("UserNumber")
    const { cart, setCart } = useContext(SessionContext);
    const { session, setSession } = useContext(SessionContext);
    const cartData = useSelector((state) => state.cart);
    const [count, setCount] = React.useState(0);
    const fetchAllCategory = async () => {
        const result = await getData('userinterface/display_all_category');
        setCategory(result.data);
    };
    useEffect(() => {
        fetchAllCategory();
    }, []);

    const func = async () => {
        var formdata = new FormData();
        formdata.append("mobile", User);
        var response = await postData("cart/getAllProducts", formdata, true);
        setCart(response.products.length);
    };
    React.useEffect(
        function () {
            func();
        },
        [cart]
    );



    // const showSubCategory = () => {
    //     return getsubcategory.map((item) => (
    //         <MenuItem key={item._id} onClick={() => handleData(item._id, item.icon)}>
    //             {item.subcategoryname}
    //         </MenuItem>
    //     ));
    // };

    const handleClick = (id, icon, categoryname) => {

        navigate(`/ProductList2/${id}/${icon}`, { state: { categoryname: categoryname } });
    };


    const handleLogout = () => {
        window.localStorage.removeItem("Token");
        window.localStorage.removeItem("UserNumber");
        window.localStorage.removeItem("UserMail")
        window.location.reload();
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const categoryItems = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 280 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}

        >
            <List sx={{ padding: 2 }}>
                
                <img src={cardImage} width={"100%"} />
                {getCategory.map((text, index) => (
                    <ListItem onClick={() => handleClick(text._id, text.icon, text.categoryname)} sx={{ background: "#ffd1d9", mt: 1, borderRadius: 1 }}>
                        <ListItemButton key={text._id} value={text._id} disablePadding >
                            <ListItemText sx={{ color: "#d24a61", width: "90%" }} primary={text.categoryname} />
                            <ListItemIcon sx={{ width: "10%" }}>
                                <img src={arrow} width={20} style={{ marginLeft: "93%" }} />
                                {/* <ArrowForwardIosIcon sx={{color:"#ffd77f",fontSize:18,minWidth:" 23px"}} /> */}
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}><MenuIcon sx={{ color: '#d24a61', fontSize: 30 }} /></Button>
                    
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}

                    >
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                    <UserComponentpannel />
                    <div style={{ fontSize: 9, textAlign: "center" }}>{Token ? "" : "Sign up"}</div>
                </div>
                <div style={{ display:'flex',justifyContent:'center',alignItems:'center',}}>{Token ? (
                    <Button sx={{fontSize: 12, color: "#ffffff", background: "#D24A61", marginLeft: 2, width: 80, "&:hover": { background: "#eabfd5", color: "#ffd77f", border: "1px solid #ffd77f" } }} onClick={() => handleLogout()}>Log Out</Button>
                ) : (
                    ""
                )}
                </div>
                <List sx={{ padding: 2 }}>
                <ListItem onClick={()=>navigate('/')} sx={{ background: "#ffd1d9", mt: 1, borderRadius: 1 }}>
                        <ListItemButton  >
                            <ListItemText sx={{ color: "#d24a61", width: "90%" }} primary='Home' />
                            <ListItemIcon sx={{ width: "10%" }}>
                                <img src={arrow} width={20} style={{ marginLeft: "93%" }} />
                                {/* <ArrowForwardIosIcon sx={{color:"#ffd77f",fontSize:18,minWidth:" 23px"}} /> */}
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                    </List>
                        {categoryItems(anchor)}
                    </Drawer>


                </React.Fragment>
            ))}
        </div>
    );
}
