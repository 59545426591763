import React from "react";
import faceBook from "../../Assets/facebook_icon.png";
import Instagram from "../../Assets/instagram_icon.png";
import WhatsApp from "../../Assets/whatsapp_icon.png";
import LinkedIn from "../../Assets/linkedin_icon.png";
import { makeStyles } from '@material-ui/core'
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import '../outline.css'
export default function DownBox() {
    const classes = useStyles();
    const theme = useTheme();
    const matches1 = useMediaQuery(theme.breakpoints.down(900));

    return (
        <div style={{  width: '100%',
        color: '#d24a61',
        paddingTop:50,
        paddingBottom:5,}} >
            <div style={{ width: "100%" }}>
                <div style={{  display: 'flex',
      width: '100%',
      flexWrap: 'wrap'}}>
                    <div style={{ display: 'flex',
      flexDirection: 'column',
      padding: '5%',
      paddingTop: '1%'}}>
                        <h1 class="fontLink" style={{ marginBottom: 15 }}>COMPANY</h1>
                        <a href="#" style={{color: '#d24a61',
      fontWeight:"400px",
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
       
      },
      lineHeight: '180%'}}>
                            About Us
                        </a>
                        <a href="#" style={{color: '#d24a61',
      fontWeight:"400px",
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
       
      },
      lineHeight: '180%'}}>
                            Term and Condition
                        </a>
                        <a href="#" style={{color: '#d24a61',
      fontWeight:"400px",
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
       
      },
      lineHeight: '180%'}}>
                            Collaboration
                        </a>
                        <a href="#" style={{color: '#d24a61',
      fontWeight:"400px",
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
       
      },
      lineHeight: '180%'}}>
                            Privacy Policy
                        </a>
                        <a href="#" style={{color: '#d24a61',
      fontWeight:"400px",
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
       
      },
      lineHeight: '180%'}}>
                            Shipping Policy
                        </a>
                        <a href="#" style={{color: '#d24a61',
      fontWeight:"400px",
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
       
      },
      lineHeight: '180%'}}>
                            Media
                        </a>
                    </div>
                    <div  style={{ marginLeft:matches1?0: 100,display: 'flex',
      flexDirection: 'column',
      padding: '5%',
      paddingTop: '1%' }}>
                        <h1 class="fontLink" style={{ marginBottom: 15 }}>NEED HELP</h1>
                        <a href="#" style={{color: '#d24a61',
      fontWeight:"400px",
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
       
      },
      lineHeight: '180%'}}>
                            FAQs
                        </a>
                        <a href="#" style={{color: '#d24a61',
      fontWeight:"400px",
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
       
      },
      lineHeight: '180%'}}>
                            Email Us
                        </a>
                        <a href="#" style={{color: '#d24a61',
      fontWeight:"400px",
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
       
      },
      lineHeight: '180%'}}>
                            Return, Refund and Cancellation Policy
                        </a>
                        <a href="#" style={{color: '#d24a61',
      fontWeight:"400px",
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
       
      },
      lineHeight: '180%'}}>
                            Track Order
                        </a>
                        <a href="#" style={{color: '#d24a61',
      fontWeight:"400px",
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
       
      },
      lineHeight: '180%'}}>
                            Carrer
                        </a>
                        <a href="#" style={{color: '#d24a61',
      fontWeight:"400px",
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
       
      },
      lineHeight: '180%'}}>
                            Site Map
                        </a>
                    </div>
                    <div   style={{ marginLeft:matches1?0: 100,display: 'flex',
      flexDirection: 'column',
      padding: '5%',
      paddingTop: '1%' }}>
                        <h1 class="fontLink" style={{ marginBottom: 15 }}>LOCATION</h1>
                        Infront of Happiness restaurant,
                        <br />   Sarafa Bazar,
                        <br /> Gwalior, Madhya Pradesh 
                        <br /> 474001
                        <br />
                        <div>
                            {" "}
                            <a href="#" style={{color: '#d24a61',
      fontWeight:"400px",
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
       
      },
      lineHeight: '180%'}}>
                                {" "}
                                hemu@gmail.com
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex',
      flexDirection: 'row',
      padding: '5%',
      paddingTop: '1%',
      [theme.breakpoints.down(900)]: {
        flexDirection:"column"
      },}}>
                    <h1 class="fontLink">Join us on</h1>
                    <div style={{display: 'flex',
      marginLeft:100,
      [theme.breakpoints.down(900)]: {
        marginLeft:-10,
        marginTop:16
      },}}>
                        <a href="#" style={{ color: 'd24a61',
      textDecoration: 'none',
      marginLeft:10,}}>
                            {" "}
                          <FacebookIcon fontSize='large' sx={{color:'#d24a61'}}/>
                        </a>
                        <a href="#" style={{ color: 'd24a61',
      textDecoration: 'none',
      marginLeft:10,}}>
                           <InstagramIcon fontSize='large' sx={{color:'#d24a61'}}/>
                        </a>
                        <a href="#" style={{ color: 'd24a61',
      textDecoration: 'none',
      marginLeft:10,}}>
                            {" "}
                         <TwitterIcon fontSize='large' sx={{color:'#d24a61'}}/>
                        </a>
                        <a href="#" style={{ color: 'd24a61',
      textDecoration: 'none',
      marginLeft:10,}}>
                            {" "}
                          <WhatsAppIcon fontSize='large' sx={{color:'#d24a61'}}/>
                        </a>
                    </div>
                </div>
                {/* <h5 className={classes.bottom}>Anywhere Fitness, a TT44 Company</h5> */}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme)=>({

    footerContainer: {
    
      
    },
  
  
    linkContainer: {
    
    },
  
  
    links: {
     
    },
  
    linksIcon: {
      
    },
  
    links2: {
      
    },
  
    aStyles: {
      
    },
  
  
    iconStyles: {
     
    },
  
    
    bottom: {
     
    },
  })  )