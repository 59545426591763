import { createStyles, makeStyles } from '@mui/styles';
import { color } from '@mui/system';

 export  const  useStyles = makeStyles({
  
     maincontainer:{
      
       
    },

    box:{ 
    },

    headingContainer:{
       
    },

    heading:{
      
    }
});