// PaymentForm.js

import React, { useState } from 'react';
import axios from 'axios';

const PaymentForm = () => {
  const [amount, setAmount] = useState('');
  const [orderId, setOrderId] = useState('');

  const handlePayment = async () => {
    try {
      const response = await axios.post('order/api', { amount, orderId });

      // Redirect to the payment gateway or handle the response as required
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Enter amount"
      />
      <input
        type="text"
        value={orderId}
        onChange={(e) => setOrderId(e.target.value)}
        placeholder="Enter order ID"
      />
      <button onClick={handlePayment}>Pay with PhonePe</button>
    </div>
  );
};

export default PaymentForm;
