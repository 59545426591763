import React from "react";
import { serverURL } from "../../Services/NodeServices";
import { useNavigate } from "react-router";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export function ProductsDetailComponent(props) {
    var navigate = useNavigate();
    const handleproduct = (items) => {
      
        navigate("/setproduct", { state: { product: items } });
    };

    return props.data.map((items) => {
        console.log();

        return (
            <Grid style={{ margin:"10px 30px"  }}>
                <Grid xs={10} md={4}>
                    <Card sx={{
                                minWidth: 200,
                                border: "none",
                                minHeight: 250,
                                border:"1px solid #d24a61"
                            }}>
                        <CardMedia
                            sx={{ width:300,height:300 }}
                            image={`${serverURL}/images/${items.picture[0]}`}
                            title="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom sx={{
                                        fontSize: { sm: 6, md: 8, lg: 10 },
                                        fontWeight: 500,
                                        textTransform:"uppercase",
                                        color: "#d24a61",
                                        textAlign:"center",
                                        
                                    }} component="div">
                                {items.productname}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {items.offerprice > 0 ? (
                                    <div style={{display:"flex",fontWeight:600,flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                                        {" "}
                                        <div>
                                        <span>&#8377; {items.offerprice}</span>
                                        <span
                                            style={{
                                                textDecoration: "line-through",
                                                color: "red",
                                                textAlign:"center",
                                                marginLeft:5
                                            }}
                                        >
                                            &#8377; {items.price}
                                        </span>
                                        </div>
                                        <span style={{ color: "green", marginLeft: 4 }}>
                                            save &#8377; {items.price - items.offerprice}
                                        </span>
                                    </div>
                                ) : (
                                    <>{items.price}</>
                                )}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="filled"
                                sx={{
                                    marginTop:-2,
                                    background: "#d24a61",
                                    fontSize: { lg: 12 },
                                    color: "#ffffff",
                                    border: "1px solid #d24a61",
                                    width: "100%",
                                    "&:hover": {
                                        backgroundColor: "#ffffff",
                                        color: "#d24a61"
                                    }
                                }}
                                onClick={() => handleproduct(items)}
                                size="small">
                                Buy Now
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        )
    });
}
