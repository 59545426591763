import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useEffect } from "react";
import { getData, postData, serverURL } from "../../Services/NodeServices";
import { MenuItem, Menu } from "@mui/material";
import { useNavigate } from "react-router";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import { ClickAwayListener, Divider } from "@mui/material";
import { NestedMenuItem } from "mui-nested-menu";
import AdbIcon from "@mui/icons-material/Adb";
import ListItemIcon from "@mui/material/ListItemIcon";
export default function MainBar() {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(900));
  const matches1 = useMediaQuery(theme.breakpoints.down(1350));
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const [getcategoryid, setcategoryid] = useState("");
  const [getsubcategoryid, setsubcategoryid] = useState("");
  const [getCategory, setCategory] = useState([]);
  const [getPriorityCategory, setPriorityCategory] = useState([]);
  const [getsubcategory, setsubcategory] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
 
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const FetchallCategoryByPriority = async () => {
    var result = await getData("userinterface/priority");
    setPriorityCategory(result.data);
  };
  const Fetchallcategory = async () => {
    var result = await getData("userinterface/display_all_category");
    setCategory(result.data);
  };

  const Fetchallsubcategory = async (categoryid) => {
    var response = await postData("userinterface/display_all_subcategory", {
      categoryid: categoryid,
    });
    setsubcategory(response.data);
  };
  useEffect(function () {
    FetchallCategoryByPriority();
    Fetchallcategory();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setcategoryid(event.currentTarget.value);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const handleNavigate = (id, icon, categoryname) => {
    setOpen(false);
    navigate(`/ProductList2/${id}/${icon}`, {
      state: { categoryname: categoryname },
    });
  };

  const handleSearch=async(keyword)=>{
        setOpen(false)
    navigate('/productlist3',{state:{keywords:keyword}} )
}
  const ShowCategory = () => {
    return getPriorityCategory.map((item) => {
      return (
        <Button
          onClick={() => handleNavigate(item._id, item.icon, item.categoryname)}
          style={{ color: "#d24a61", fontSize: 18, margin: "0px 10px" }}
        >
          {item.categoryname}
        </Button>
      );
    });
  };
  const handleData = (scid, icon) => {
    navigate(`/ProductList/${scid}/${icon}`, { state: { id: scid } });
  };

  const ShowAllCategory = () => {
    return;
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, display: matches ? "none" : "block" }}>
        <AppBar position="static" style={{ backgroundColor: "#FFF" }}>
          <Toolbar>
            <div
              style={{
                display: "flex",
                justifyContent: 'space-between',
                width: "100%",

              }}
            >
              
              <Button
                // aria-owns={anchorElServicesMenu ? "simple-menu" : undefined}
                // aria-haspopup="true"
                // onClick={handleServicesOpen}
                // onMouseOver={handleServicesOpen}
                // sx={{ my: 2, color: "#fff" }}
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onMouseEnter={handleToggle}
                onMouseLeave={handleToggle}
                style={{ color: "#d24a61", fontSize: 18, textDecoration: "none" }}
              >
                All Categories
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  sx={{ zIndex: 1 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onMouseAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                            style={{ fontWeight: "bold" }}
                          >
                            <NestedMenuItem
                              
                              label="Our All Categories"
                              sx={{color:"#d24a61"}}
                              parentMenuOpen={true}
                            >
                              {getCategory.map((item) => {
                                return <MenuItem sx={{color:"#d24a61"}} onClick={() => handleNavigate(item._id, item.icon, item.categoryname)}>{item.categoryname}</MenuItem>;
                              })}
                            </NestedMenuItem>
                            <Divider />
                            <NestedMenuItem
                             
                              label="Shop By Material"
                              sx={{color:"#d24a61"}}
                              parentMenuOpen={true}
                            >
                              <MenuItem onClick={()=>handleSearch("Gold")} sx={{color:"#d24a61"}}>Gold Jewellery</MenuItem>
                              <MenuItem onClick={()=>handleSearch("Diamond")} sx={{color:"#d24a61"}}>Diamond Jewellery</MenuItem>
                              <MenuItem onClick={()=>handleSearch("stone")} sx={{color:"#d24a61"}}>Stone Jewellery</MenuItem>
                              <MenuItem onClick={()=>handleSearch("pearl")} sx={{color:"#d24a61"}}>Pearl Jewellery</MenuItem>
                            </NestedMenuItem>
                            {/* <Divider /> */}
                            {/* <NestedMenuItem
                              leftIcon={<AdbIcon />}
                              label="Shop Now For"
                              sx={{color:"#d24a61"}}
                              parentMenuOpen={true}
                            >
                              <MenuItem sx={{color:"#d24a61"}}>Men</MenuItem>
                              <MenuItem sx={{color:"#d24a61"}}>WoMen</MenuItem>
                              <MenuItem sx={{color:"#d24a61"}}>Children</MenuItem>
                            </NestedMenuItem> */}
                            {/* <Divider /> */}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Button>
              {matches ? "" : ShowCategory()}
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
